// Override default variables before the import
$body-bg: #ffffff;
$default-color: #2a2c2b;
$company-color: #0094ec;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');


.HelpCenterWrapper {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .HelpCenterInnerWrapper {
        width: 75%;
    }

    .TabBar {
        width: 100%;
    }

    .contactsupportpadding{
        padding-top: 2rem;
    }
}

.KnowledgeBaseWrapper {
    display: flex;
    //    margin-left: 4rem;
    //  margin-right: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    .Link {
        position: relative;
        list-style: none;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        background-color: transparent;
        display: inline-block;
        text-decoration: none;
        color: rgb(139, 149, 158);
    }

    .Link:hover {
        color: black;
    }

    h1 {
        font-weight: 600;
        line-height: 1.125em;
        color: rgb(25, 28, 31);
        font-size: 2rem;
        margin: 0px;
        padding: 0px 0px 15px;
    }

    h2 {
        font-weight: 600;
        line-height: 1.125em;
        letter-spacing: -0.025em;
        color: rgb(25, 28, 31);
        font-size: 1rem;
        text-transform: uppercase;
    }

    .BreadcrumbsWrapper {
        padding-top: 1rem;
        padding-left: 3rem;
        padding-right: 4rem;
        width: 100%;
        align-content: center;

        ol {
            background-color: white;
        }

        li::before {
            content: none;
            position: absolute;
            top: 0.5rem;
            left: -1.5rem;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: rgb(139, 149, 158);
        }

        li::after {
            border-style: solid;
            border-color: rgb(139, 149, 158);
            border-width: 2px 2px 0px 0px;
            content: "";
            display: inline-block;
            height: 0.45em;
            top: -0.05em;
            left: 1em;
            transform: rotate(45deg);
            position: relative;
            vertical-align: central;
            width: 0.45em;
            background: none;
            border-radius: 0px;
        }

        li {
            position: relative;
            color: rgb(139, 149, 158);
            list-style: none;
            margin: 0px 30px 0px 0px;
            padding: 0px 0px;
            display: list-item;
            vertical-align: central;
        }

        li:last-child {
            margin-right: 15px;
        }

        a {
            position: relative;
            list-style: none;
            cursor: pointer;
            box-sizing: border-box;
            outline: none;
            background-color: transparent;
            display: inline-block;
            text-decoration: none;
            color: rgb(139, 149, 158);
        }

        a:hover {
            color: black;
        }
    }

    .CategoriesWrapper {
        display: flex;
        width: 100%;
        min-height: 500px;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .CategoryList {
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 3rem;
            padding-right: 3rem;
            min-width: 20rem;
            display: inline-block;

            ul {
                display: block;
                list-style-type: disc;
                list-style: none;
                margin: 0px;
                padding: 0px;
            }

            li {
                margin: 0px;
                padding: 8px 0px;
                position: relative;
                color: rgb(139, 149, 158);
                list-style: none;
                box-sizing: border-box;
                line-height: 1.15;
                outline: none;
            }

            .ArticleList {
            }

            .CategoryListDescription {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                max-width: 25rem;
                font-weight: 500;
                line-height: 1.125em;
                font-size: 1rem;
            }
        }
    }

    .SectionWrapper {
        min-height: 500px;
        width: 100%;

        .SectionName {
            padding-top: 1rem;
            padding-left: 4rem;
            padding-right: 4rem;
            width: 100%;
        }

        .ArticleList {
            padding-left: 4rem;
            padding-right: 4rem;
            padding-bottom: 1rem;
            column-count: 2;
            column-gap: 3rem;
            display: inline-block;
            width: 100%;

            ul {
                display: block;
                list-style-type: disc;
                list-style: none;
                margin: 0px;
                padding: 0px;
            }

            li {
                margin: 0px;
                padding: 8px 0px;
                position: relative;
                color: rgb(139, 149, 158);
                list-style: none;
                box-sizing: border-box;
                line-height: 1.15;
                outline: none;
            }
        }
    }

    .CategoryWrapper {
        min-height: 500px;
        width: 100%;

        .CategoryName {
            padding-top: 1rem;
            padding-left: 4rem;
            padding-right: 4rem;
            width: 100%;
        }

        .SectionsList {
            padding-bottom: 1rem;
            padding-left: 4rem;
            padding-right: 4rem;
            width: 100%;
            min-height: 600px;
            columns: 2;
            column-gap: 1rem;

            .SectionArticleList {
                display: inline-block;
                padding-bottom: 1rem;
                min-width: 40em;

                ul {
                    display: block;
                    list-style-type: disc;
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                }

                li {
                    margin: 0px;
                    padding: 8px 0px;
                    position: relative;
                    color: rgb(139, 149, 158);
                    list-style: none;
                    box-sizing: border-box;
                    line-height: 1.15;
                    outline: none;
                }
            }
        }
    }

    .Article {
        max-width: 95rem;
        padding-left: 4rem;
        padding-right: 4rem;
        flex-wrap: wrap;
        display: flex;
        background-color: #FFFFFF;
        color: #333333;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
        font-weight: 400;
        line-height: 1.5;

        @media (min-width:1025px) {
            max-width: 90%;
        }

        -webkit-font-smoothing: antialiased;

        h1, h2, h3, h4, h5, h6 {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
            font-weight: 400;
            margin-top: 0;
            width: 100%;
        }

        h1 {
            font-size: 2em;
            margin: 0.67em 0;
            padding-bottom: 0.37em;
            border-bottom: 1px solid silver;
        }

        h1 {
            font-size: 32px;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
        }

        h4 {
            font-size: 16px;
            font-weight: 700;
        }

        h6 {
            text-align: center;
        }
        // Article specific formatting
        img[alt="Zapier Logo"] {
            width: 160px;
            max-height: 100px;
        }

        img[alt="Zendesk Logo"] {
            max-height: 100px;
        }

        .what-languages-do-we-process {

            td {
                padding-right: 1.5rem;
                padding-bottom: .5rem;
            }
        }
    }
}
