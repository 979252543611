// Override default variables before the import
$body-bg: #ffffff;
$default-color: #2a2c2b;
$company-color: #0094ec;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

html, .body, .div {
    font-family: 'Lato', sans-serif;
    margin: 0 !important;
    padding-top: 80px !important;
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.container-fluid {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.companycolour {
    color: $company-color;
}

.columnsort:hover {
    cursor: pointer
}

.row {
    font-family: 'Lato', sans-serif;
}

.navbar-3scribe {
    width: 98vw;
    color: #2a2c2b;
    margin-left: -15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
    padding-left: 35px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.20em;

    .navbar-brand {
        color: #0094ec;
        font-size: larger;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4em;
    }

    .navbar-brand:hover {
        color: #0094ec;
    }

    .navbar-brand:visited {
        color: #0094ec;
    }

    .nav {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 35px;
        padding-right: 35px;
    }

    .nav-link {
        color: #2a2c2b;
    

    .nav-barlink {
        color: pink;
        padding-left: 6em;
    }}

    a {
        color: #2a2c2b;
    }

    .active {
        color: #2a2c2b !important;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.06em;
    }

    .link {
        color: #2a2c2b;
    }
}

.bannerbackground {
    margin-top: -80px;
    padding-left: 30px;
    width: 100vw;
    height: 100vh;
    background-color: #f9f7f4;
    color: #2a2c2b !important;
    text-align: left;
}

.infobannerbackground {
    margin-top: -80px;
    padding-left: 30px;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    color: #2a2c2b !important;
    text-align: left;
}

.infobanner {
    background-color: #ffffff;
    color: #2a2c2b !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
    text-align: left;
    line-height: normal;
}

.infobannerborder {
    border-bottom: 1px solid $company-color;
}

.banner {
    padding-left: 30px;
    background-color: #f9f7f4;
    color: #2a2c2b !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
    text-align: left;
    line-height: normal;
}

.bannersubtext {
    font-size: 0.45em;
    color: #2a2c2b !important;
    line-height: initial;
}

.loginpanel {
    padding: 30px;
}

.fillerpanel {
    padding: 50px;
}

.smallfillerpanel {
    padding: 25px;
}

.addflex {
    display: flex;
}

.centertextv {
    display: flex;
    align-content: center;
    flex-direction: column;
}

.centertexth {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.lefttexth {
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.centertext {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.box-header {
    font-family: 'Lato', sans-serif;
    color: $default-color !important;
    font-weight: 600;
    font-size: 1.26em;
    padding-bottom: 30px;
}

.box-text {
    font-family: 'Lato', sans-serif;
    color: $default-color !important;
    font-weight: 400;
    font-size: 1.0em;
    padding-bottom: 30px;
}

.companycolor {
    color: $company-color
}

.login {
    font-family: 'Lato', sans-serif;
    color: $default-color;
}

.footer-3scribe {
    left: 0;
    overflow: hidden;
    font-family: 'Lato', sans-serif;
    background-color: $default-color;
    color: rgba(255, 255, 255, 0.65);
    padding-bottom: 10px;
    padding-top: 20px;
    width: 100vw;

    a {
        font-size: 1.0em;
        color: rgba(255,255,255,0.65);
    }

    a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    h5 {
        color: #ffffff;
        font-weight: 800;
    }

    h6 {
        color: #ffffff;
        font-weight: 600;
    }
}

.footerlogo {
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.56em;
}

.tableheader {
    font-weight: 600;
    color: #2a2c2b;
    font-size: 1.06em;
    line-height: 2;
}

.tableitem {
    font-weight: 400;
    color: #2a2c2b;
    font-size: 1.06em;
    line-height: 1.6;

    a {
        color: $company-color;
        font-weight: 600;
    }

    a:hover {
        color: $company-color;
        text-decoration: none;
    }
}

.marker {
    border: 1px solid lime;
}

.marker2 {
    border: 1px solid pink;
}

.marker3 {
    border: 1px solid orange;
}

.marker4 {
    border: 1px solid blue;
}

.listminheight {
    min-height: 400px;
}

.trash {
    color: red;
    cursor: pointer;
}

.download {
    color: $company-color;
    cursor: pointer;
}

.restart {
    color: green;
    cursor: pointer;
}

.makebold {
    font-weight: 600;
}

.standardfont {
    font-family: 'Lato', sans-serif;
    color: $default-color !important;
    font-weight: 400;
    font-size: .56em;
}

.currentbalance {
    font-family: 'Lato', sans-serif;
    color: $default-color !important;
    padding: 20px;

    .header {
        color: $default-color !important;
        font-weight: 400;
        font-size: 1.66em;
    }

    .text {
        padding-top: 10px;
        font-weight: 400;
        font-size: 1.06em;
    }

    .nocredit {
        font-weight: 600;
        color: red;
    }

    .incredit {
        font-weight: 600;
        color: green;
    }
}

.addpointer {
    cursor: pointer;
}

.apibox {
    font-family: 'Lato', sans-serif;
    color: $default-color !important;
    padding: 30px;

    .header {
        color: $default-color !important;
        font-weight: 400;
        font-size: 1.66em;
        padding-bottom: 15px;
    }

    .subheader {
        color: $default-color !important;
        font-weight: 600;
        font-size: 1.26em;
        padding-bottom: 15px;
        align-content: center;
        vertical-align: middle;
        padding-top: 15px;
        background-color: ghostwhite;
    }

    .text {
        padding-top: 40px;
        font-weight: 400;
        font-size: 1.06em;
        align-content: flex-start;
    }
}

.largetext {
    padding-top: 40px;
    font-weight: 400;
    font-size: 1.66em;
    align-content: flex-start;
}

.text {
    padding-top: 40px;
    font-weight: 400;
    font-size: 1.06em;
    align-content: flex-start;
    white-space: pre-wrap;
}

.smallertext {
    padding-top: 10px;
    font-weight: 400;
    font-size: .90em;
    align-content: flex-start;
}

.integrationcard {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 350px;

    a {
        color: $default-color;
        text-decoration: none;
    }

    a:hover {
        color: $default-color;
        text-decoration: none;
    }

    .card {
        padding-top: 15px;
        padding-bottom: 15px;
        display: block;
    }

    .imagewrapper {
        display: flex;
        min-height: 150px;
    }

    img {
        display: block;
        max-width: 50%;
        height: auto;
    }
}

.jobrow {
    padding-bottom: 5px;
}

.fullwidth {
    width: 100%;
}

.StripeElement {
    box-sizing: border-box;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.65rem 0.75rem;
    border: 1px solid #ced4da;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    border-radius: .25rem;
    background-color: white;
}

.waitpanel {
    background-color: #ffffff;
    opacity: .80;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.waitpaneltext {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    color: darkgreen;
    font-size: 36px;
}

.toast-message {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.06em;
    color: darkgreen;
    align-content: flex-start;
}

.ConfirmButton {
    background-color: $company-color;
}

.RegisterButton {
    min-width: 160px
}

.heropanel {
    padding-top: 70px !important;
}

.NewsletterCheckbox {
    margin-top: 0.3rem !important;
}

.jobsourcelogolarge {
    width: 64px;
    height: 64px;
}


.width50 {
    width: 50%;
}

.width60 {
    width: 60%;
}

.width80 {
    width: 80%;
}

.leftjustified {
    text-align: left;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Integration Cards */

.integration-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #F6F6F6;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.integration-card {
    width: 14em;
    display: block;
    overflow: hidden;
    margin: 15px;
    min-width: 350px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2),0 5px 15px 0 rgba(37,44,97,0.15);
    background-color: white;
    transition: box-shadow .2s ease,transform .2s ease
}

.integration-card-body {
    padding: 15px 45px 25px;
    width: 100%
}
.integration-card-image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 140px;
    width: 100%;
    background-color: #dadada;
}
.integration-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    object-fit:contain;
}
.integration-card-image-small {
    max-width: 50%;
}
.integration-card-title {
    margin-bottom: 20px
}
.integration-card-text {
}









.posts-05 {
    padding: 70px 0 70px
}

.posts-05__wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap
}

.posts-05__item {
    display: block;
    overflow: hidden;
    width: 30%;
    margin: 15px;
    min-width: 350px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2),0 5px 15px 0 rgba(37,44,97,0.15);
    background-color: white;
    transition: box-shadow .2s ease,transform .2s ease
}

@media (max-width: 600px) {
    .posts-05__item {
        width: 100%;
        min-width: auto
    }
}

.posts-05__img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 140px
}

.posts-05__img_box--small {
    height: 20px
}

.posts-05__img {
    max-width: 70%;
    max-height: 100px
}

.posts-05__text {
    padding: 15px 45px 25px;
    width: 100%
}

@media (max-width: 600px) {
    .posts-05__text {
        padding-right: 15px;
        padding-left: 15px
    }
}

.posts-05__title {
    margin-bottom: 20px
}

.font-source-code-pro .posts-05__title {
    font-size: 16px
}

.posts-05__title_info {
    opacity: 0.6;
    margin-left: 7px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400
}

.posts-05__info {
    line-height: 1.4
}

.font-source-code-pro .posts-05__info {
    font-size: 14px
}

.posts-05__link {
    margin-top: 20px
}
